<template>
  <div type="primary" class="ns-button vote-button" @click.stop="onVoteClick" :loading="isVoting">투표</div>
</template>

<script>
import * as API from '@/utils/api'
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVoting: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isLogin']),
  },
  methods: {
    ...mapMutations('auth', ['showKakaoLoginModal', 'setToken']),
    async requestVote() {
      // this.isVoting = true
      this.$showLoading()
      try {
        const res = await API.vote(this.id)
        this.$emit('voted', res.vote_amount)
        const accessToken = res.access_token
        if (accessToken) {
          this.setToken(accessToken)
        }
      } catch (err) {
        this.$showErrorMessage(err)
      }
      // this.isVoting = false
      this.$hideLoading()
    },
    onVoteClick() {
      // if (!this.isLogin) {
      //   if (this.$route.path === '/') {
      //     this.$router.push({ name: 'Login' })
      //   } else {
      //     this.showKakaoLoginModal()
      //   }
      // } else {
      //   this.requestVote()
      // }
      this.$toast.info('투표가 종료되었습니다.', { position: 'top' })
    },
  },
}
</script>
<style lang="scss" scoped>
.vote-button {
  color: white !important;
  background: $t-primary-500 !important;
}
</style>
