<template>
  <div class="ns-button" @click="showShareLinkModal">공유</div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    shareLinkModal() {
      return this.$refs.shareLinkModal
    },
  },
  methods: {
    ...mapMutations('media', ['setShareLink']),
    showShareLinkModal() {
      this.setShareLink(this.id)
    },
  },
}
</script>
