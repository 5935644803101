import Vue from 'vue'
import store from '@/store'

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
}

const request = async (method, url, data) => {
  const token = store.state.auth.token
  const refreshToken = store.state.auth.refreshToken
  const headers = { Authorization: `bearer ${token}` }
  let options = { method, url, headers }
  if (method === METHOD.GET) {
    options = { ...options, params: data }
  } else {
    const params = { ...data, refresh_token: refreshToken }
    options = { ...options, data: params }
  }

  const res = await Vue.prototype.$axios(options)
  return res.data.result || res.data
}

export const vote = post_id => {
  return request(METHOD.POST, `/api/posts/${post_id}/votes`, { post_id })
}

export const getPosts = () => {
  return request(METHOD.GET, '/api/posts')
}

export const getPostByVotes = () => {
  return request(METHOD.GET, `/api/posts?order=votes`)
}

export const getPost = post_id => {
  return request(METHOD.GET, `/api/posts/${post_id}`)
}
